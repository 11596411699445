export enum SelectedEnum {
    NONE,
    CHARACTER,
    INITIATIVE,
    SETTINGS,
    ADMIN,
    MONSTER,
    BOOKS,
    ENCOUNTER
}
